import request from "@/utils/request";

// EquipmentCategory
export function equipmentCategoryList(params) {
  return request({ url: `/equipment_categories/`, method: "get", params });
}

export function equipmentCategoryCreate(data) {
  return request({ url: `/equipment_categories/`, method: "post", data });
}

export function equipmentCategoryUpdate(data) {
  return request({ url: `/equipment_categories/${data.id}/`, method: "put", data });
}

export function equipmentCategoryDestroy(data) {
  return request({ url: `/equipment_categories/${data.id}/`, method: "delete", data });
}

// Equipment
export function equipmentList(params) {
  return request({ url: `/equipments/`, method: "get", params });
}

export function getEquipmentNumber(params) {
  return request({ url: `/equipments/number/`, method: "get", params });
}

export function equipmentCreate(data) {
  return request({ url: `/equipments/`, method: "post", data });
}

export function equipmentUpdate(data) {
  return request({ url: `/equipments/${data.id}/`, method: "put", data });
}

export function equipmentDestroy(data) {
  return request({ url: `/equipments/${data.id}/`, method: "delete", data });
}

// MaintenanceItem
export function maintenanceItemList(params) {
  return request({ url: `/maintenance_items/`, method: "get", params });
}

export function getMaintenanceItemNumber(params) {
  return request({ url: `/maintenance_items/number/`, method: "get", params });
}

export function maintenanceItemCreate(data) {
  return request({ url: `/maintenance_items/`, method: "post", data });
}

export function maintenanceItemUpdate(data) {
  return request({ url: `/maintenance_items/${data.id}/`, method: "put", data });
}

export function maintenanceItemDestroy(data) {
  return request({ url: `/maintenance_items/${data.id}/`, method: "delete", data });
}

// MaintenancePlan
export function maintenancePlanList(params) {
  return request({ url: `/maintenance_plans/`, method: "get", params });
}

export function getMaintenancePlanNumber(params) {
  return request({ url: `/maintenance_plans/number/`, method: "get", params });
}

export function maintenancePlanCreate(data) {
  return request({ url: `/maintenance_plans/`, method: "post", data });
}

export function maintenancePlanUpdate(data) {
  return request({ url: `/maintenance_plans/${data.id}/`, method: "put", data });
}

export function maintenancePlanDestroy(data) {
  return request({ url: `/maintenance_plans/${data.id}/`, method: "delete", data });
}

export function maintenancePlanComfirm(data) {
  return request({ url: `/maintenance_plans/${data.id}/comfirm/`, method: "post", data });
}

export function maintenancePlanClose(data) {
  return request({ url: `/maintenance_plans/${data.id}/close/`, method: "post", data });
}

// MaintenanceTask
export function maintenanceTaskList(params) {
  return request({ url: `/maintenance_tasks/`, method: "get", params });
}

// MaintenanceRecord
export function maintenanceRecordList(params) {
  return request({ url: `/maintenance_records/`, method: "get", params });
}

export function maintenanceRecordCreate(data) {
  return request({ url: `/maintenance_records/`, method: "post", data });
}

export function maintenanceRecordDestroy(data) {
  return request({ url: `/maintenance_records/${data.id}/`, method: "delete", data });
}
